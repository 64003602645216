body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

body {
	position: relative;
}

.alignItemsRight {
	margin-left: auto;
	margin-right: 0;
}

.owaisLogo {
	float: left;
	width: 21%;
}

.owaisLogoNav {
	float: left;
	width: 21%;
}

@media screen and (max-width: 1192px) {
	.mainImage {
		margin-top: 2.6vw !important;
	}
}

@media screen and (max-width: 1034px) {
	.mainImage {
		margin-top: 3.3vw !important;
	}
}

@media screen and (max-width: 804px) {
	.mainImage {
		margin-top: 4.7vw !important;
	}
}

@media screen and (max-width: 515px) {
	.mainImage {
		margin-top: 5.7vw !important;
	}
}

@media screen and (max-width: 445px) {
	.mainImage {
		margin-top: 6.7vw !important;
	}
}

@media screen and (max-width: 392px) {
	.mainImage {
		margin-top: 8.2vw !important;
	}
}

@media screen and (max-width: 340px) {
	.mainImage {
		margin-top: 9.5vw !important;
	}
}


@media screen and (max-width: 900px) {
	.owaisLogoNav {
		display: none;
	}
}


@media screen and (min-width: 700px),
screen and (max-width: 992px) {
	.owaisLogo {
		height: 25% !important;
		width: 60% !important;
	}
}

@media screen and (max-width: 786px) {
	.owaisLogo {
		width: 61% !important;
		height: 19% !important;
	}
}

@media screen and (max-width: 767px) {
	.owaisLogo {
		width: 55% !important;
		height: 38% !important;
	}
}

@media screen and (max-width: 451px) {
	.owaisLogo {
		width: 53% !important;
		height: 28% !important;
	}
}

@media screen and (max-width: 371px) {
	.owaisLogo {
		width: 67% !important;
		height: 28% !important;
	}
}




/* --- Contact Us Page --- */

.main {
	width: 100%;
	background-color: #E4E5E8;
	position: absolute;
}

body .contactMe {
	background-color: #e5e5f7;
	background-image: radial-gradient(#444cf7 0.5px, #e5e5f7 0.5px);
	background-size: 10px 10px;
}

.containerWrap {
	margin-top: 8vw;
}

.containerWrap h1 {
	font-weight: 750;
	font-size: 42px;
	text-transform: capitalize;
	text-align: center;
}

.containerWrap h4 {
	text-align: center;
}

.containerWrap h6 {
	text-align: center;
}

.calendlyWrap {
	margin-top: 1.6vw;
}

.calendly-inline-widget {
	height: 53.4vw !important;
}

.mainDesc {
	color: #827c85;
	font-weight: 600;
	color: #8b93a3;
	font-size: 1.18vw;
}

@media only screen and (max-width: 1299px) and (min-width: 913px) {
	.calendly-inline-widget {
		height: 57.4vw !important;
	}
}

@media only screen and (max-width: 1144px) {
	.calendly-inline-widget {
		height: 69.4vw !important;
	}
}

@media only screen and (max-width: 1000px) {
	.calendly-inline-widget {
		height: 81.4vw !important
	}
}

@media only screen and (max-width: 980px) {
	.calendly-inline-widget {
		height: 105.4vw !important
	}
}

@media only screen and (max-width: 923px) {
	.calendly-inline-widget {
		height: 120.4vw !important
	}
}

@media only screen and (max-width: 809px) {
	.calendly-inline-widget {
		height: 130.4vw !important
	}
}

@media only screen and (max-width: 746px) {
	.calendly-inline-widget {
		height: 140.4vw !important
	}
}

@media only screen and (max-width: 693px) {
	.calendly-inline-widget {
		height: 150.4vw !important
	}
}

@media only screen and (max-width: 664px) {
	.calendly-inline-widget {
		height: 163.4vw !important
	}
}

@media only screen and (max-width: 565px) {
	.calendly-inline-widget {
		height: 180.4vw !important
	}
}

@media only screen and (max-width: 565px) {
	.calendly-inline-widget {
		height: 195.4vw !important
	}
}

@media only screen and (max-width: 473px) {
	.calendly-inline-widget {
		height: 209.4vw !important
	}
}

@media only screen and (max-width: 445px) {
	.calendly-inline-widget {
		height: 235.4vw !important
	}
}

@media only screen and (max-width: 407px) {
	.calendly-inline-widget {
		height: 249.4vw !important
	}
}

@media only screen and (max-width: 383px) {
	.calendly-inline-widget {
		height: 269.4vw !important
	}
}

@media only screen and (max-width: 383px) {
	.calendly-inline-widget {
		height: 280.4vw !important
	}
}

@media only screen and (max-width: 344px) {
	.calendly-inline-widget {
		height: 299.4vw !important
	}
}

Button {
	display: none;
}





/* Testimonials */

.slick-slide {
	height: auto;
}

.slick-track {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: stretch;
}


.slick-slide div:first-child {
	outline: none;
	padding: 1.9vw 1.7vw;
}

.slick-slider {
	margin: auto 3vw;
	width: 95%;
	height: 100%;
}

.sliderEdit .slick-slider {
	margin: auto 3vw;
	width: 95%;
	height: 100%;
}

@media only screen and (max-width: 1000px) {
	.sliderEdit .slick-slider {
		width: 90%;
		margin-left: 5.3vw;
	}
}

/* @media only screen and (max-width: 687px) {
	.sliderEdit .slick-slider  {
		margin: ;
	}
} */

.slick-list {
	margin: 0 -5px;
}

.slick-slide>div {
	padding: 0 5px;
	height: auto;
}

.card {
	border-radius: 25px;
	background-color: #FFF;
}

.image-content {
	position: relative;
	row-gap: 5px;
	padding: 0.99vw 1.6vw;
	display: flex;
	flex-direction: row;
}

.overlay {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #222438;
	border-radius: 25px 25px 0 25px;
}

.card-image {
	position: relative;
	height: 150px;
	width: 150px;
	border-radius: 50%;
	background: #FFF;
	padding: 3px;
	height: 100px;
	width: 100px;
}

.card-image .card-img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 50%;
	border: 4px solid #222438;
}

.info {
	z-index: 1;
	margin-left: 6%;
}

.name {
	font-size: 18px;
	font-weight: 500;
	color: white;
	padding: 0;
}

.designation {
	font-size: 13px;
	font-weight: 200;
	color: white;
}

.location {
	font-size: 12px;
	font-weight: 200;
	color: white;
}

.description {
	font-size: 15px;
	color: #5a5a5a;
	text-align: justify;
	overflow: hidden;
	height: 330px;
}

.mainImage {
	background-color: #000000;
	position: relative;
	text-align: center;
	color: white;
}

.mainImage img {
	opacity: 0.6;
	width: 100%;
	height: 100%;
}

.mainPageHeading {
	color: white;
	font-size: 5.2vw;
	font-weight: bold;
	z-index: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media (max-width: 825px) {
	.description {
		height: 300px;
	}
}

@media (max-width: 367px) {
	.description {
		height: 350px;
	}
}

@media (max-width: 326px) {
	.description {
		height: 380px;
	}
}

/* Honors */

.honorsHeading {
	padding: 34px;
	font-size: 35px;
	font-weight: 700;
	text-align: center;
}

.mainWrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: auto 10vw;
	gap: 2.5vw;
}

.mainWrapperTwo {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: auto 10vw;
	gap: 2.5vw;
}

.flip-box {
	background-color: transparent;
	width: 23vw;
	height: 17.8vw;
	border: 1px solid #f1f1f1;
	perspective: 1000px;
	border-radius: 10px;
	overflow: hidden;
}

.flip-box-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.8s;
	transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
	transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.flip-box-front {
	background-color: #bbb;
	color: black;
}

.flip-box-back {
	background-color: #222438;
	color: white;
	transform: rotateY(180deg);
	font-size: 0.9vw;
	padding: 2vw 0.5vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.imageCard {
	width: 100%;
	height: 100%;
	background-size: cover;
}

.mainWrapperTwo .flip-box:last-child {
	justify-self: center;
	grid-column-start: span 3;
	margin-bottom: 10px;
	margin-right: 1.2vw;
}


/* Research */

.introContainer {
	margin: auto 67px;
}

.introWrap {
	display: flex;
	justify-content: space-evenly;
	margin-top: 3vw;
}

.chairmanPic {
	width: 33%;
	height: 48vw;
}

.introPara {
	width: 24%;
	font-size: 1.5vw;
	line-height: 2.7vw;
}

.researchHeading {
	width: 100%;
	background-color: #222438;
	height: fit-content;
	margin-top: 0.7vw;
}

.researchHeading h1 {
	color: white;
	text-align: center;
	padding: 1vw 4vw;
	font-size: 30px;
}

.researchHeading h5 {
	color: white;
	text-align: center;
	font-weight: 400;
	padding: 0.2vw 0 1vw 0;
	font-size: 1.3vw;
}

.vrParaGrapgh {
	width: 37%;
	font-size: 1.7vw;
	line-height: 2.7vw;
	height: fit-content;
	flex: 0.8;
}

.vrImgContainer {
	display: flex;
	flex-direction: column;
}

.vrImgOne {
	width: 104%;
	height: 23vw;
	margin-bottom: 0.4vw;
}

.vrImgTwo {
	width: 100%;
	height: 19vw;

}

.scrmlContainer {
	display: inline;
	width: 24%;
	font-size: 1.5vw;
	line-height: 2.7vw;
}

.scrmlPara {
	font-size: 1.7vw;
	line-height: 2.7vw;
	height: fit-content;
	display: inline-block;
	margin: 1vw 5vw;
}

.scrmlImgContainer {
	display: flex;
	flex-direction: column;
	margin: 1vw 5vw;
}

.scrmlImgContainer>img:first-child {
	margin-bottom: 0.9vw;
}



#offices {
	display: flex;
	margin: 1vw 5vw;
}

.col {
	flex: 1;
}

#officesImg {
	line-height: 0;
	display: flex;
}

.row img {
	height: 49%;
	width: 98%;

}

#officesImg img {
	width: 100%;
	height: auto;
}

.stackImage {
	float: left;
	width: 48% !important;
}

.firstCol {
	display: block;
	margin: 12px 82px;
}


.containerForVr {
	margin: 12px 82px;
	margin: 1vw 5vw;
	display: flex;
	justify-content: space-between;
}

.pictureContainer {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.box-layout {
	display: inline-block;
	width: 47%;
	float: left;
	overflow: hidden;
}

.lastContainer {
	margin: 1vw 5vw;
}


@media (max-width: 1304px) {
	.lastContainer .box-layout {
		width: 100%;
		margin-top: 0.5vw;
		margin-bottom: 0.5vw;
	}
}

@media (max-width: 664px) {
	.lastContainer .box-layout img {
		width: 100%;
	}
}

@media (max-width: 734px) {
	.containerWrap h1 {
		margin-top: 15vw !important;
	}
}

@media (max-width: 649px) {
	.containerWrap h1 {
		margin-top: 14vw !important;
		font-size: 5vw;
	}


	.containerWrap h4 {
		font-size: 2.8vw;
	}

	.containerWrap h6 {
		font-size: 2.2vw;
		margin-bottom: 5vw;
	}
}

@media (max-width: 860px) {
	.researchHeading h1 {
		font-size: 17px;
	}

	.researchHeading h5 {
		font-size: 1.8vw;
	}

	.mainPageHeading {
		font-size: 32px;
	}

	.growthImage {
		margin-top: 2.2vw;
		margin-bottom: 2.2vw;
	}

	.secondHeadingAbout {
		margin-top: 14% !important;
		font-size: 2.5vw !important;
	}

}

/* @media (max-width: 702px) {
	.researchHeading h1 {
		font-size: 15px;
	}

} */




/* Training*/

.imgMargin {
	margin-bottom: 9px;
}

.pageMainHeading {
	margin: 4vw 5vw;
	font-size: 1.3vw;
	font-weight: 500;
	text-align: center;
	line-height: 2.3vw;
}

.trainingContent {
	margin: 1.2vw 5.7vw;
}

.myStoryContent {
	margin: 1.2vw 5.7vw;
}

.trainingContent #section_1,
.trainingContent #section_2,
.trainingContent #section_3,
.trainingContent #section_5 .myStoryContent #section_1,
.myStoryContent #section_2,
.myStoryContent #section_3,
.myStoryContent #section_5 {
	display: flex;
}

.trainingContent #section_1,
.trainingContent #section_2,
.trainingContent #section_3,
.trainingContent #section_5,
.myStoryContent #section_1,
.myStoryContent #section_2,
.myStoryContent #section_3,
.myStoryContent #section_5 {
	margin-top: 4vw;

}

.trainingContent #section_1 p,
.trainingContent #section_2 p,
.trainingContent #section_3 p,
.trainingContent #section_5 p,
.myStoryContent #section_1 p,
.myStoryContent #section_2 p,
.myStoryContent #section_3 p,
.myStoryContent #section_5 p {
	line-height: 3.25vw;
	font-size: 1.5vw;
}

.typoSection {
	margin-right: 1.4vw;
}

.typoSection h3 {
	font-size: 2.35vw;
}

.typoSection h5 {
	font-size: 1.3vw;
}

.section_1:nth-child(2),
.section_1:nth-child(3) {
	margin-top: 10vw;
}



@media (max-width: 1323px) {

	.trainingContent #section_1,
	.trainingContent #section_2,
	.trainingContent #section_3,
	.trainingContent #section_5 {
		flex-direction: column;
	}

	.grid-header img {
		width: 100%;
	}

	.grid-content .block:nth-child(3) {
		margin-top: 0.5vw;
	}

	.myStoryWrap .imgMargin {
		width: 100%;
	}

	.imgMargin {
		width: 100%;
	}
}

@media (max-width: 737px) {
	.grid-content {
		display: flex !important;
		flex-direction: column !important;
	}

	.grid-content .block:nth-child(3) {
		margin-top: 0.5vw;
	}
}



@media (max-width: 912px) {
	.typoSection h3 {
		font-size: 3vw;
	}
}

.text-justify {
	display: inline-block;
	color: white;
}

.site-footer {
	background-color: #26272b;
	padding: 45px 0 20px;
	font-size: 15px;
	line-height: 24px;
	color: #737373;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.site-footer hr {
	border-top-color: #bbb;
	opacity: 0.5;
}

.site-footer hr.small {
	margin: 20px 0;
}

.site-footer h6 {
	color: #fff;
	font-size: 16px;
	text-transform: uppercase;
	margin-top: 5px;
	letter-spacing: 2px;
}

.site-footer a {
	color: #737373;
}

.site-footer a:hover {
	color: #fff;
	text-decoration: none;
}

.footer-links {
	padding-left: 0;
	list-style: none;
}

.footer-links li {
	display: block;
}

.footer-links a {
	color: #fff;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
	color: #fff;
	text-decoration: none;
}

.site-footer .social-icons {
	text-align: right;
}

.site-footer .social-icons a {
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin-left: 6px;
	margin-right: 0;
	border-radius: 100%;
}

.copyright-text {
	margin: 0;
}

@media (max-width: 991px) {
	.site-footer [class^="col-"] {
		margin-bottom: 30px;
	}
}

@media (max-width: 767px) {
	.site-footer {
		padding-bottom: 0;
	}

	.site-footer .copyright-text,
	.site-footer .social-icons {
		text-align: center;
	}
}

.social-icons {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.social-icons li {
	display: inline-block;
	margin-bottom: 4px;
}

.logo {
	color: #eb6857;
}

.grid-wrapper-1 {
	display: grid;
	grid-template: 276px 1fr / .1fr 1fr;
	grid-template-areas: "header header" "sidebar main";
	height: 100vh
}

.grid-wrapper-2 {
	display: grid;
	grid-template: 310px 1fr / .1fr 1fr;
	grid-template-areas: "header header" "sidebar main";
	height: 100vh
}

.grid-header {
	grid-area: header;
}

.grid-sidebar {
	grid-area: sidebar;
}

.grid-main {
	grid-area: main;
}

.grid-content {
	display: grid;
	grid-template: 1fr 1fr / 1fr 1fr 1fr;
	grid-template-areas: "block-1 block-2 block-4" "block-1 block-3 block-5";
	height: 100%;
	grid-gap: 7px 7px;
	margin-top: 9px;
}

.block:nth-child(1),
.block:nth-child(2),
.block:nth-child(3) {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4px;
}

.block:nth-child(1) {
	grid-area: block-1
}

.block:nth-child(2) {
	grid-area: block-2
}

.block:nth-child(3) {
	grid-area: block-3;
	margin-top: -10px;
}

/* My Story */

.wrapStory {
	display: flex;
}

.wrapStory p {
	margin-right: 10%;
}

.section_one_story p {
	line-height: 3.25vw;
	font-size: 21px;
}

.wrapper {
	display: flex;
	width: 100%;
	flex: auto;
}

.wrapper-2 {
	display: flex;
	width: 100%;
	flex: auto;
	margin-top: 3vw;
}

.paragraph {
	line-height: 3.25vw;
	font-size: 1.7vw;
	width: 50%;
	margin-right: 2vw;
}

.image-div-1 {
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669725394/Owais/myStory_1_cfl5wb.png');
	background-size: cover;
	width: 50%;
}

.image-div-2 {
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669725394/Owais/myStory_2_huklhv.png');
	background-position: center;
	background-size: cover;
	width: 50%;
}

.image-div-3 {
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669725394/Owais/myStory_3_wocpxf.png');
	background-size: cover;
	width: 50%;
}

.image-div-4 {
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669725394/Owais/myStory_4_hncxlu.png');
	background-size: cover;
	width: 50%;
}

.image-div-5 {
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669725394/Owais/myStory_5_vc3hid.png');
	background-size: cover;
	width: 50%;
}

/* About me */
.mainHeadingAbout {
	color: white;
	font-size: 3.15vw;
	font-weight: bold;
	z-index: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	margin-top: 3%;
}

.secondHeadingAbout {
	color: white;
	font-size: 2.1vw;
	font-weight: bold;
	z-index: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-top: 11%
}

.expertiseContainer {
	display: grid;
	grid-template-columns: auto auto auto;
	padding: 0.65vw;
	gap: 0.6vw 0.6vw;
}

.expertiseContainer .grid-item {
	border-radius: 8px;
	text-align: center;
	text-align: center;
	background: linear-gradient(#f8f8f8, #fff);
	box-shadow: 0 8px 16px -8px rgb(0 0 0 / 40%);
	border-radius: 6px;
	font-size: 20px;
	padding: 28px;
	margin: 1.5rem;
	background: #222438;
	color: white;
	font-weight: 400;
	background: #043570;
	background: -webkit-linear-gradient(top left, #043570, #7D93E8);
	background: -moz-linear-gradient(top left, #043570, #7D93E8);
	background: linear-gradient(to bottom right, #043570, #7D93E8);
}

.skillsContainer {
	display: grid;
	grid-template-columns: auto auto auto auto;
	padding: 0.65vw;
	gap: 0.6vw 0.6vw;
}

.skillsContainer .grid-item {
	border-radius: 8px;
	font-size: 1.55vw;
	font-size: 20px;
	padding: 28px;
	text-align: center;
	background: linear-gradient(#f8f8f8, #fff);
	box-shadow: 0 8px 16px -8px rgb(0 0 0 / 40%);
	border-radius: 6px;
	margin: 1.5rem;
	background: #222438;
	color: white;
	font-weight: 400;
	background: #043570;
	background: -webkit-linear-gradient(top left, #043570, #7D93E8);
	background: -moz-linear-gradient(top left, #043570, #7D93E8);
	background: linear-gradient(to bottom right, #043570, #7D93E8);
	display: flex;
	justify-content: space-around;
}

.sliderEdit .slick-list {
	height: auto;
}

@media (max-width: 500px) {
	.sliderEdit img {
		height: auto !important;
	}

	.sliderEdit .slick-list {
		height: 70vw;
	}
}

.marginWrap {
	margin: 1vw 5vw;
}

ol {
	counter-reset: li;
	list-style: none;
	padding: 0;
	text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
	padding-left: 0;
}

ol a {
	position: relative;
	display: block;
	padding: 1.4em 0.4em 1.8em 2.2em !important;
	margin: 1.5em 0;
	background: #222438;
	color: white;
	text-decoration: none;
	border-radius: 0.3em;
	font-size: 1.2vw;
	font-weight: 500;
	width: 45%;
	text-align: center;
}

ol a:hover {
	background: #E9E4E0;
}

ol a:hover:before {
	transform: rotate(360deg);
}

ol a:before {
	content: counter(li);
	counter-increment: li;
	position: absolute;
	left: -1.3em;
	top: 50%;
	margin-top: -1.3em;
	background: #3C4D5F;
	height: 2.5em;
	width: 2.5em;
	line-height: 2em;
	border: .3em solid #fff;
	text-align: center;
	font-weight: bold;
	border-radius: 2em;
}

.growthImage {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: 55%;
	height: 34vw;
	overflow: hidden;
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669722565/Owais/growthCycle_lhtsow.png');
	background-size: cover;
	background-position: center;
	margin-top: 2, 2vw;
	margin-bottom: 2.2vw;
}

.poemWrap {
	margin: 1vw 5vw;
	text-align: -webkit-center;
}

.skillLogo img {
	width: 50px;
	height: 50px;
}

.about-me-1 {
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669889427/Owais/aboutme_1_us1gxg.jpg');
	background-size: cover;
	width: 50%;
}

.about-me-2 {
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669721701/Owais/aboutme_2_uakv5s.png');
	background-size: cover;
	width: 50%;
}

.about-me-3 {
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669721700/Owais/aboutme_3_uidudd.png');
	background-size: cover;
	width: 50%;
}

.about-me-4 {
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669721702/Owais/aboutme_4_pvwqzy.png');
	background-size: cover;
	width: 50%;
}

.about-me-5 {
	background-image: url('https://res.cloudinary.com/drsqh0aog/image/upload/v1669721701/Owais/aboutme_5_xxsyek.png');
	background-size: cover;
	width: 50%;
}

.showMoreButton {
	color: white;
	background: #222438;
	padding: 0.7vw;
	border-radius: 0.5vw;
	font-size: 1.3vw;
	display: block;
}

.showMoreButton:hover {
	background: #0f1019;
}

.readMoreHead {
	float: right;
	font-weight: 100;
	font-size: 1vw;
}

/* Footer */

.marginHead {
	margin-top: 1.5vw;
}

.emailText {
	text-transform: lowercase !important;
}

.skypeImg {
	width: 57px !important;
	height: 57px !important;
}

.myStoryWrap .imgMargin {
	width: 43.3vw;
}


@media (max-width: 1200px) {
	.skillsContainer {
		grid-template-columns: auto auto auto;
	}

}

@media (max-width: 940px) {

	.expertiseContainer,
	.skillsContainer {
		grid-template-columns: auto auto;
	}

	.pageMainHeading span {
		font-size: 1.9vw;
		line-height: 2.7vw;
	}

	.typoSection h5 {
		font-size: 1.8vw;
	}

}

@media (max-width: 530px) {

	.expertiseContainer,
	.skillsContainer {
		grid-template-columns: auto;
	}

	.trainingContent #section_1 p,
	.trainingContent #section_2 p,
	.trainingContent #section_3 p,
	.trainingContent #section_5 p {
		font-size: 2.2vw;
	}


}